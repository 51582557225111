<template lang="pug">
#ClipPath
  #ClipPath__left
    .heading clip-path
    .inner-box
      .description-label description
      .description.
        Animating a clip-path for simple shapes is easy, but what is more complicated is to use an SVG clipPath
        property in order to scale and update its position on the screen using the mouse.
        <br><br>
        Well... this does exactly that :)
    .icons-container
      template(v-for="item in iconsArray" :key="item")
        div(@click=`selectSvg(item)`, :class=`item === selected.svg ? 'is-selected' : ''` )
  #ClipPath__image
    #clip-path-image
    #svg-container
      <svg id="the-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

      <svg>
      <clipPath id="svg__apple">
      <path d="M185.255,512c-76.201-0.439-139.233-155.991-139.233-235.21c0-129.404,97.075-157.734,134.487-157.734c16.86,0,34.863,6.621,50.742,12.48c11.104,4.087,22.588,8.306,28.975,8.306c3.823,0,12.832-3.589,20.786-6.738   c16.963-6.753,38.071-15.146,62.651-15.146c0.044,0,0.103,0,0.146,0c18.354,0,74.004,4.028,107.461,54.272l7.837,11.777   l-11.279,8.511c-16.113,12.158-45.513,34.336-45.513,78.267c0,52.031,33.296,72.041,49.292,81.665   c7.061,4.248,14.37,8.628,14.37,18.208c0,6.255-49.922,140.566-122.417,140.566c-17.739,0-30.278-5.332-41.338-10.034   c-11.191-4.761-20.845-8.862-36.797-8.862c-8.086,0-18.311,3.823-29.136,7.881C221.496,505.73,204.752,512,185.753,512H185.255z"></path>
      <path d="M351.343,0c1.888,68.076-46.797,115.304-95.425,112.342C247.905,58.015,304.54,0,351.343,0z"></path>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__cross">
      <path d="M412.861,78.976c3.404-6.636,2.831-14.159-0.15-20.404c0.84-7.106-1.02-14.321-7.746-19.855c-6.262-5.151-12.523-10.305-18.781-15.457c-11.005-9.055-28.237-11.913-38.941,0c-48.619,54.103-99.461,105.856-152.167,155.725c-39.185-36.605-78.846-72.713-118.223-108.868c-13.82-12.693-33.824-8.71-42.519,6.411c-12.665,6.286-22.931,14.481-31.42,28.468c-4.042,6.664-3.727,15.076,0,21.764c25.421,45.578,74.557,85.651,114.957,122.529c-5.406,4.839-10.772,9.724-16.287,14.461c-54.43,46.742-91.144,76.399-23.029,124.325c0.919,0.647,1.856,0.504,2.789,0.882c1.305,0.602,2.557,1.026,4.004,1.264c0.45,0.017,0.87,0.093,1.313,0.058c1.402,0.114,2.774,0.471,4.195,0.192c36.621-7.18,70.677-35.878,101.576-67.48c30.1,29.669,62.151,58.013,97.395,74.831c8.391,4.005,18.395,1.671,24.855-3.931c10.832,0.818,20.708-5.913,25.665-15.586c0.734-0.454,1.207-0.713,2.002-1.21c15.748-9.838,17.187-29.431,5.534-42.936c-26.313-30.492-54.284-59.478-82.798-87.95C316.426,196.043,380.533,141.939,412.861,78.976z"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__firefox">
      <path d="M356.192,11.136c49.44,33.6,77.92,122.208,77.92,189.216c0,17.12-2.24,33.824-6.24,49.408c-3.584-38.944-23.616-73.216-53.44-95.488c16.48,22.24,26.272,49.632,26.272,79.456c0,73.696-59.872,133.568-133.568,133.568c-29.824,0-44.512-5.792-66.784-22.272c45.184,0,72.992-44.512,111.296-44.512c0,0-5.536-22.272-33.376-22.272c-27.84,0-14.688,22.272-66.784,22.272s-77.92-31.392-77.92-55.648c0-24.256,36.064-41.856,44.512-33.376c7.584-7.584,0-22.272,0-22.272l66.816-44.512c0,0-11.136,0-22.272,0c-98.848,0-42.08-69.248-22.272-89.056c-35.392,0-57.664,32.736-66.816,44.512c-5.568-2.656-44.512-2.656-55.648,0c-4.896,1.12-11.776-7.552-18.24-19.584c-8-15.136-15.136-35.392-15.136-47.2c-28.256,28.288-23.36,70.592-12.224,87.264c-0.448,0.448-0.896,1.12-1.12,1.792C11.36,158.72,0,200.576,0,244.864c0,141.344,114.656,256,256,256S512,397.344,512,256c0-2.464,0-19.808,0-22.272C512,101.568,427.424,32.48,356.192,11.136z"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__selection">
      <path d="m357.972656 335.359375-330.664062-330.667969c-3.011719-3.027344-7.042969-4.691406-11.308594-4.691406-8.832031 0-16 7.167969-16 16v480c0 8.832031 7.167969 16 16 16 4.332031 0 8.535156-1.792969 11.519531-4.886719l139.285157-144.445312h179.863281c8.832031 0 16-7.167969 16-16 0-4.269531-1.664063-8.300781-4.695313-11.308594zm0 0"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__like">
      <path d="M448,192H320V96c0-35.296-28.704-64-64-64h-48c-8.832,0-16,7.168-16,16v75.744l-61.888,108.32C128.704,234.496,128,237.216,128,240v224c0,8.832,7.168,16,16,16h256.8c25.28,0,48.256-14.944,58.464-38.016l51.328-115.488C511.52,324.448,512,322.24,512,320v-64C512,220.704,483.296,192,448,192z"/>
      <path d="M16,224c-8.832,0-16,7.168-16,16v224c0,8.832,7.168,16,16,16h80V224H16z"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__star">
      <path d="m510.652344 185.882812c-3.371094-10.367187-12.566406-17.707031-23.402344-18.6875l-147.796875-13.417968-58.410156-136.75c-4.3125-10.046875-14.125-16.53125-25.046875-16.53125s-20.738282 6.484375-25.023438 16.53125l-58.410156 136.75-147.820312 13.417968c-10.835938 1-20.011719 8.339844-23.402344 18.6875-3.371094 10.367188-.257813 21.738282 7.9375 28.925782l111.722656 97.964844-32.941406 145.085937c-2.410156 10.667969 1.730468 21.699219 10.582031 28.097656 4.757813 3.457031 10.347656 5.183594 15.957031 5.183594 4.820313 0 9.644532-1.28125 13.953125-3.859375l127.445313-76.203125 127.421875 76.203125c9.347656 5.585938 21.101562 5.074219 29.933593-1.324219 8.851563-6.398437 12.992188-17.429687 10.582032-28.097656l-32.941406-145.085937 111.722656-97.964844c8.191406-7.1875 11.308594-18.535156 7.9375-28.925782zm-252.203125 223.722657"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__thunder">
      <path d="M397.413,199.303c-2.944-4.576-8-7.296-13.408-7.296h-112v-176c0-7.552-5.28-14.08-12.672-15.648c-7.52-1.6-14.88,2.272-17.952,9.152l-128,288c-2.208,4.928-1.728,10.688,1.216,15.2c2.944,4.544,8,7.296,13.408,7.296h112v176c0,7.552,5.28,14.08,12.672,15.648c1.12,0.224,2.24,0.352,3.328,0.352c6.208,0,12-3.616,14.624-9.504l128-288C400.805,209.543,400.389,203.847,397.413,199.303z"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__twitter">
      <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568C480.224,136.96,497.728,118.496,512,97.248z"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__youtube">
      <path d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__user">
      <path d="m210.351562 246.632812c33.882813 0 63.222657-12.152343 87.195313-36.128906 23.972656-23.972656 36.125-53.304687 36.125-87.191406 0-33.875-12.152344-63.210938-36.128906-87.191406-23.976563-23.96875-53.3125-36.121094-87.191407-36.121094-33.886718 0-63.21875 12.152344-87.191406 36.125s-36.128906 53.308594-36.128906 87.1875c0 33.886719 12.15625 63.222656 36.132812 87.195312 23.976563 23.96875 53.3125 36.125 87.1875 36.125zm0 0"/>
      <path d="m426.128906 393.703125c-.691406-9.976563-2.089844-20.859375-4.148437-32.351563-2.078125-11.578124-4.753907-22.523437-7.957031-32.527343-3.308594-10.339844-7.808594-20.550781-13.371094-30.335938-5.773438-10.15625-12.554688-19-20.164063-26.277343-7.957031-7.613282-17.699219-13.734376-28.964843-18.199219-11.226563-4.441407-23.667969-6.691407-36.976563-6.691407-5.226563 0-10.28125 2.144532-20.042969 8.5-6.007812 3.917969-13.035156 8.449219-20.878906 13.460938-6.707031 4.273438-15.792969 8.277344-27.015625 11.902344-10.949219 3.542968-22.066406 5.339844-33.039063 5.339844-10.972656 0-22.085937-1.796876-33.046874-5.339844-11.210938-3.621094-20.296876-7.625-26.996094-11.898438-7.769532-4.964844-14.800782-9.496094-20.898438-13.46875-9.75-6.355468-14.808594-8.5-20.035156-8.5-13.3125 0-25.75 2.253906-36.972656 6.699219-11.257813 4.457031-21.003906 10.578125-28.96875 18.199219-7.605469 7.28125-14.390625 16.121094-20.15625 26.273437-5.558594 9.785157-10.058594 19.992188-13.371094 30.339844-3.199219 10.003906-5.875 20.945313-7.953125 32.523437-2.058594 11.476563-3.457031 22.363282-4.148437 32.363282-.679688 9.796875-1.023438 19.964844-1.023438 30.234375 0 26.726562 8.496094 48.363281 25.25 64.320312 16.546875 15.746094 38.441406 23.734375 65.066406 23.734375h246.53125c26.625 0 48.511719-7.984375 65.0625-23.734375 16.757813-15.945312 25.253906-37.585937 25.253906-64.324219-.003906-10.316406-.351562-20.492187-1.035156-30.242187zm0 0"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__switzerland">
      <path d="M20.305,9.675c-0.777,0.939-1.399,0.64-1.954-0.221c-0.147-0.23-0.34-0.325-0.593-0.372c-1.365-0.258-1.353-0.948-0.669-1.955c0.315-0.464,0.732-1.201,0.236-1.603c-0.767-0.622-1.631-1.198-2.648-1.417c-0.551,0.573-1.14,0.512-1.765,0.094c-0.791-0.526-1.439-0.636-1.331,0.635c0.027,0.316-0.044,0.552-0.259,0.422c-0.867-0.519-1.616,0.451-2.526,0.137C8.554,5.311,7.673,4.953,7.264,5.617C6.825,6.327,6.319,6.44,5.551,6.013C4.873,5.636,4.195,6.148,4.501,6.74c0.364,0.7-0.025,0.943-0.338,1.172c-1.45,1.061-1.931,2.901-3.296,4.021C0.73,12.045,0.69,12.276,0.61,12.454c-0.339,0.749,0.408,1.745-0.578,2.394c-0.149,0.1,0.256,0.495,0.58,0.37c0.399-0.153,0.713-0.34,0.841-0.889c0.209-0.892,1.15-0.617,1.78-0.804c0.562-0.166,0.625,0.364,0.684,0.723c0.385,2.354,1.123,2.882,3.271,2.316c0.229-0.062,0.507-0.137,0.713-0.064c1.386,0.48,2.088-0.65,2.263-1.457c0.195-0.903,0.714-1.249,1.204-1.782c0.456,0.39,0.029,0.887,0.331,1.282c0.701,0.923,1.963,1.372,2.212,2.686c0.037,0.195,0.591,0.138,0.537-0.102c-0.301-1.304,0.854-2.041,1.124-3.107c0.088-0.353-0.287-0.881,0.216-1.036c0.537-0.17,0.556,0.396,0.7,0.739c0.154,0.364,0.492,0.603,0.818,0.435c0.89-0.461,1.554-0.104,2.239,0.527c-0.057-0.559,0.28-1.074-0.202-1.506c-0.382-0.34-0.095-0.611,0.117-0.877c0.264-0.328,0.449-0.144,0.711,0.06c0.286,0.219,1.042,0.295,0.938,0.063c-0.412-0.91,0.199-1.72,0.039-2.545C21.056,9.408,20.687,9.214,20.305,9.675z"/>
      <path d="M14.626,3.841c-0.031-0.014-0.08,0.024-0.121,0.036c0.063,0.062,0.127,0.122,0.19,0.185C14.753,3.971,14.747,3.891,14.626,3.841z"/>
      <path d="M14.695,4.062c-0.012,0.015-0.005,0.029-0.021,0.044l-0.001,0.002c0.001,0,0.002,0,0.003,0c0.012-0.012,0.024-0.015,0.036-0.028C14.706,4.074,14.7,4.068,14.695,4.062z"/></clipPath>
      </svg>

      <svg>
      <clipPath id="svg__switzerland_flag">
      <path d="M256,324.571c-74.339,0-137.143,33.496-137.143,73.143S181.661,470.857,256,470.857s137.143-33.496,137.143-73.143S330.339,324.571,256,324.571z M210.286,425.143h9.143c5.047-0.007,9.136-4.096,9.143-9.143c0-5.049,4.093-9.143,9.143-9.143s9.143,4.093,9.143,9.143c-0.017,15.142-12.287,27.412-27.429,27.429h-9.143c-15.142-0.017-27.412-12.287-27.429-27.429v-36.571c0.016-15.142,12.287-27.412,27.429-27.429h9.143c15.142,0.017,27.412,12.287,27.429,27.429c0,5.049-4.093,9.143-9.143,9.143s-9.143-4.093-9.143-9.143c-0.007-5.047-4.096-9.136-9.143-9.143h-9.143c-5.047,0.007-9.136,4.096-9.143,9.143V416C201.149,421.047,205.239,425.136,210.286,425.143z M329.143,434.286c0,5.049-4.093,9.143-9.143,9.143c-5.049,0-9.143-4.093-9.143-9.143v-27.429h-27.429v27.429c0,5.049-4.093,9.143-9.143,9.143c-5.049,0-9.143-4.093-9.143-9.143v-73.143c0-5.049,4.093-9.143,9.143-9.143c5.049,0,9.143,4.093,9.143,9.143v27.429h27.429v-27.429c0-5.049,4.093-9.143,9.143-9.143c5.049,0,9.143,4.093,9.143,9.143V434.286z"/>
      <path d="M237.714,105.143v82.286c0,5.049-4.093,9.143-9.143,9.143h-82.286v36.571h82.286c5.049,0,9.143,4.093,9.143,9.143v64.654c5.998-0.397,12.078-0.654,18.286-0.654s12.287,0.257,18.286,0.654v-64.654c0-5.049,4.093-9.143,9.143-9.143h82.286v-36.571h-82.286c-5.049,0-9.143-4.093-9.143-9.143v-82.286H237.714z"/>
      <path d="M502.857,41.143H9.143C4.096,41.149,0.007,45.239,0,50.286v329.143c0.007,5.047,4.096,9.136,9.143,9.143h92.189c6.399-39.579,54.046-70.956,118.097-79.74v-57.403h-82.286c-5.049,0-9.143-4.093-9.143-9.143v-54.857c0-5.049,4.093-9.143,9.143-9.143h82.286V96c0-5.049,4.093-9.143,9.143-9.143h54.857c5.049,0,9.143,4.093,9.143,9.143v82.286h82.286c5.049,0,9.143,4.093,9.143,9.143v54.857c0,5.049-4.093,9.143-9.143,9.143h-82.286v57.403c64.051,8.784,111.698,40.161,118.097,79.74h92.189c5.047-0.007,9.136-4.096,9.143-9.143V50.286C511.993,45.239,507.904,41.149,502.857,41.143z"/>
      </clipPath>
      </svg>

      <svg>
      <clipPath id="svg__youtube">
      <path d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z"/>
      </clipPath>
      </svg>

      </svg>
</template>

<script>
import { onMounted, reactive } from 'vue';

export default {
  name: 'ClipPath',
  setup () {
    let selected = reactive({
      svg: 'switzerland',
    });

    let iconsArray = [
      'switzerland',
      'switzerland_flag',
      'selection',
      'like',
      'star',
      'apple',
      'cross',
      'user',
    ];

    let clipPath;
    let layerX;
    let layerY;
    let image;


    const pointerEnter = () => {
      if (image) image.style.clipPath = `url(#svg__${selected.svg})`;
    };


    const pointerMove = event => {
      if (clipPath && image) {
        const imageContainer = document.getElementById('ClipPath__image');
        let _container = imageContainer.getBoundingClientRect();

        layerX = event.x - _container.left;
        layerY = event.y - _container.top;

        clipPath.style.transition = ``;

        switch (selected.svg) {
          case 'apple':
          case 'star':
          case 'like':
            clipPath.style.transform = `translate(${layerX - 180}px, ${layerY - 200}px) scale(0.7)`;
            break;

          case 'switzerland':
            clipPath.style.transform = `translate(${layerX - 220}px, ${layerY - 200}px) scale(20)`;
            break;

          case 'selection':
            clipPath.style.transform = `translate(${layerX - 80}px, ${layerY - 180}px) scale(0.7)`;
            break;

          default:
            clipPath.style.transform = `translate(${layerX - 150}px, ${layerY - 150}px) scale(.7)`;
        }
      }
    };


    const pointerLeave = () => {
      if (clipPath && image) {
        clipPath.style.transition = `all 200ms linear`;
        image.style.clipPath = '';
      }
    };


    onMounted(() => {
      const imageContainer = document.getElementById('ClipPath__image');
      imageContainer.onpointerenter = pointerEnter;
      imageContainer.onpointermove = pointerMove;
      imageContainer.onpointerleave = pointerLeave;

      image = document.getElementById('clip-path-image');
      clipPath = document.getElementById(`svg__${selected.svg}`);
    });


    const selectSvg = value => {
      if (value) {
        selected.svg = value;
        clipPath = document.getElementById(`svg__${selected.svg}`);
      }
    };


    return {
      selectSvg,
      iconsArray,
      selected,
    };
  },
};
</script>

<style lang="stylus" scoped>
#ClipPath
  position absolute
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  border-radius 20px
  overflow hidden
  display flex
  align-items stretch
  #ClipPath__left
    position relative
    flex 1
    background-image radial-gradient(circle at 50% 0, #3a416f, #21243d)
    text-align center
    color white
    padding 20px 48px 48px 48px
    box-shadow: 0 5px 15px 0 rgba(37, 44, 97, .15), 0 2px 4px 0 rgba(93, 100, 148, .2);
    display flex
    flex-direction column
    max-width 600px
    min-width 500px
    .heading
      font 700 27px / 1.3 Averta, sans-serif;
      margin-bottom 20px
    .inner-box
      position relative
      box-shadow: inset 0 10px 10px 10px rgba(0, 0, 0, 0.05);
      padding 40px 35px 20px 35px
      margin 24px 0 0 0
      border-radius 8px
      background-color #21243d
      border 1px solid #3a416f
      .description-label
        position absolute
        top 0
        left 50%
        color #3a416f
        padding 8px 12px
        border-radius 2px
        transform translateX(-50%) translateY(-50%)
        background-image linear-gradient(#e0e0ff, #ededff)
        text-transform uppercase
        letter-spacing 1.5px
        font 700 10px / 1 Averta, sans-serif;
        box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 1), 0 2px 4px 0 rgba(93, 100, 148, 1);
      .description
        font 400 15px / 1.7 Averta, sans-serif;
    .icons-container
      flex 1
      display flex
      flex-wrap wrap
      align-self stretch
      align-content center
      > div
        align-self flex-end
        flex 1
        min-width 25%
        height 70px
        margin 20px 0 0 0
        background-repeat no-repeat
        background-position center
        background-size 30px
        opacity 0.45
        filter invert()
        &.is-selected
          opacity 1
        &:hover
          opacity 1
        &:nth-child(1)
          background-image url('../../public/clip-path/switzerland.svg')
        &:nth-child(2)
          background-image url('../../public/clip-path/switzerland_flag.svg')
        &:nth-child(3)
          background-image url('../../public/clip-path/selection.svg')
        &:nth-child(4)
          background-image url('../../public/clip-path/like.svg')
        &:nth-child(5)
          background-image url('../../public/clip-path/star.svg')
        &:nth-child(6)
          background-image url('../../public/clip-path/apple.svg')
        &:nth-child(7)
          background-image url('../../public/clip-path/cross.svg')
        &:nth-child(8)
          background-image url('../../public/clip-path/user.svg')
  #ClipPath__image
    position relative
    background-image url('../../public/clip-path/_matterhorn.webp')
    background-position center
    background-size cover
    flex 2
    display flex
    align-items stretch
    background-color gray
    #clip-path-image
      width 100%
      height 100%
      background-image url('../../public/clip-path/_swiss_flag.webp')
      background-position center
      background-size cover
      clip-path circle(0 at 0 0)
    #svg-container
      position absolute
      top 0
      left 0
      bottom 0
      right 0
      opacity 0
      #the-svg
        position absolute
        top 0
        left 0
        width 100%
        height 100%
</style>